import Router from "vue-router";
import Vue from "vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import ('@/views/Home')
    },
    {
        path: "/about/company",
        name: "Company",
        component: () => import ('@/views/about/Company')
    },
    {
        path: "/about/culture",
        name: "Culture",
        component: () => import ('@/views/about/Culture')
    },
    {
        path: "/about/team",
        name: "Team",
        component: () => import ('@/views/about/Team')
    },
    {
        path: "/about/news",
        name: "News",
        component: () => import ('@/views/News')
    }, {
        path: "/about/social",
        name: "SocialJob",
        component: () => import ('@/views/about/SocialJob')
    }, {
        path: "/about/social-poster",
        name: "SocialPoster",
        component: () => import ('@/views/about/SocialPoster')
    }, {
        path: "/about/livestream-poster",
        name: "LivestreamPoster",
        component: () => import ('@/views/about/LivestreamPoster')
    }, {
        path: "/about/campus",
        name: "CampusJob",
        component: () => import ('@/views/about/CampusJob')
    }, {
        path: "/about/campus-poster",
        name: "CampusPoster",
        component: () => import ('@/views/about/CampusPoster')
    }, {
        path: "/product/platform",
        name: "Platform",
        component: () => import ('@/views/product/Platform')
    }, {
        path: "/product/algorithm",
        name: "Algorithm",
        component: () => import ('@/views/product/Algorithm')
    }, {
        path: "/product/t0",
        name: "T0",
        component: () => import ('@/views/product/T0')
    }, {
        path: "/product/bus",
        name: "Bus",
        component: () => import ('@/views/product/Bus')
    }, {
        path: "/news01",
        name: "News01",
        component: () => import ('@/views/news/News01')
    }, {
        path: "/news02",
        name: "News02",
        component: () => import ('@/views/news/News02')
    }, {
        path: "/news03",
        name: "News03",
        component: () => import ('@/views/news/News03')
    }, {
        path: "/news04",
        name: "News04",
        component: () => import ('@/views/news/News04')
    }, {
        path: "/news05",
        name: "News05",
        component: () => import ('@/views/news/News05')
    }, {
        path: "/news06",
        name: "News06",
        component: () => import ('@/views/news/News06')
    }, {
        path: "/news07",
        name: "News07",
        component: () => import ('@/views/news/News07')
    }, {
        path: "/news08",
        name: "News08",
        component: () => import ('@/views/news/News08')
    }, {
        path: "/news09",
        name: "News09",
        component: () => import ('@/views/news/News09')
    },{
        path: "/news10",
        name: "News10",
        component: () => import ('@/views/news/News10')
    },{
        path: "/news11",
        name: "News11",
        component: () => import ('@/views/news/News11')
    },{
        path: "/news12",
        name: "News12",
        component: () => import ('@/views/news/News12')
    },{
        path: "/news13",
        name: "News13",
        component: () => import ('@/views/news/News13')
    },{
        path: "/news14",
        name: "News14",
        component: () => import ('@/views/news/News14')
    },{
        path: "/news15",
        name: "News15",
        component: () => import ('@/views/news/News15')
    },{
        path: "/news16",
        name: "News16",
        component: () => import ('@/views/news/News16')
    },{
        path: "/news17",
        name: "News17",
        component: () => import ('@/views/news/News17')
    },{
        path: "/news18",
        name: "News18",
        component: () => import ('@/views/news/News18')
    },{
        path: "/news19",
        name: "News19",
        component: () => import ('@/views/news/News19')
    },{
        path: "/news20",
        name: "News20",
        component: () => import ('@/views/news/News20')
    },{
        path: "/news21",
        name: "News21",
        component: () => import ('@/views/news/News21')
    },{
        path: "/news22",
        name: "News22",
        component: () => import ('@/views/news/News22')
    },{
        path: "/news23",
        name: "News23",
        component: () => import ('@/views/news/News23')
    },{
        path: "/news25",
        name: "News25",
        component: () => import ('@/views/news/News25')
    },{
        path: "/news26",
        name: "News26",
        component: () => import ('@/views/news/News26')
    },{
        path: "/news27",
        name: "News27",
        component: () => import ('@/views/news/News27')
    },{
        path: "/news28",
        name: "News28",
        component: () => import ('@/views/news/News28')
    },{
        path: "/news29",
        name: "News29",
        component: () => import ('@/views/news/News29')
    },{
        path: "/news30",
        name: "News30",
        component: () => import ('@/views/news/News30')
    },{
        path: "/news31",
        name: "News31",
        component: () => import ('@/views/news/News31')
    },{
        path: "/news32",
        name: "News32",
        component: () => import ('@/views/news/News32')
    },{
        path: "/news33",
        name: "News33",
        component: () => import ('@/views/news/News33')
    },{
        path: "/news34",
        name: "News34",
        component: () => import ('@/views/news/News34')
    },{
        path: "/news35",
        name: "News35",
        component: () => import ('@/views/news/News35')
    },{
        path: "/news36",
        name: "News36",
        component: () => import ('@/views/news/News36')
    },{
        path: "/news37",
        name: "News37",
        component: () => import ('@/views/news/News37')
    },{
        path: "/news38",
        name: "News38",
        component: () => import ('@/views/news/News38')
    },{
        path: "/news39",
        name: "News39",
        component: () => import ('@/views/news/News39')
    },{
        path: "/news40",
        name: "News40",
        component: () => import ('@/views/news/News40')
    },{
        path: "/news41",
        name: "News41",
        component: () => import ('@/views/news/News41')
    },{
        path: "/news42",
        name: "News42",
        component: () => import ('@/views/news/News42')
    },{
        path: "/news43",
        name: "News43",
        component: () => import ('@/views/news/News43')
    },{
        path: "/news44",
        name: "News44",
        component: () => import ('@/views/news/News44')
    },{
        path: "/news45",
        name: "News45",
        component: () => import ('@/views/news/News45')
    },{
        path: "/news46",
        name: "News46",
        component: () => import ('@/views/news/News46')
    },{
        path: "/news47",
        name: "News47",
        component: () => import ('@/views/news/News47')
    },{
        path: "/news48",
        name: "News48",
        component: () => import ('@/views/news/News48')
    },{
        path: "/news49",
        name: "News49",
        component: () => import ('@/views/news/News49')
    },{
        path: "/news50",
        name: "News50",
        component: () => import ('@/views/news/News50')
    },{
        path: "/news51",
        name: "News51",
        component: () => import ('@/views/news/News51')
    },{
        path: "/news52",
        name: "News52",
        component: () => import ('@/views/news/News52')
    },{
        path: "/news53",
        name: "News53",
        component: () => import ('@/views/news/News53')
    },{
        path: "/news54",
        name: "News54",
        component: () => import ('@/views/news/News54')
    },{
        path: "/news55",
        name: "News55",
        component: () => import ('@/views/news/News55')
    },{
        path: "/news56",
        name: "News56",
        component: () => import ('@/views/news/News56')
    },{
        path: "/news57",
        name: "News57",
        component: () => import ('@/views/news/News57')
    },{
        path: "/news58",
        name: "News58",
        component: () => import ('@/views/news/News58')
    },{
        path: "/news59",
        name: "News59",
        component: () => import ('@/views/news/News59')
    },{
        path: "/news60",
        name: "News60",
        component: () => import ('@/views/news/News60')
    },{
        path: "/news61",
        name: "News61",
        component: () => import ('@/views/news/News61')
    },{
        path: "/news62",
        name: "News62",
        component: () => import ('@/views/news/News62')
    },{
        path: "/news63",
        name: "News63",
        component: () => import ('@/views/news/News63')
    },{
        path: "/news64",
        name: "News64",
        component: () => import ('@/views/news/News64')
    },{
        path: "/news65",
        name: "News65",
        component: () => import ('@/views/news/News65')
    },{
        path: "/news66",
        name: "News66",
        component: () => import ('@/views/news/News66')
    },{
        path: "/news67",
        name: "News67",
        component: () => import ('@/views/news/News67')
    },{
        path: "/news68",
        name: "News68",
        component: () => import ('@/views/news/News68')
    },{
        path: "/news69",
        name: "News69",
        component: () => import ('@/views/news/News69')
    },{
        path: "/news70",
        name: "News70",
        component: () => import ('@/views/news/News70')
    },{
        path: "/news71",
        name: "News71",
        component: () => import ('@/views/news/News71')
    },{
        path: "/news72",
        name: "News72",
        component: () => import ('@/views/news/News72')
    },{
        path: "/news73",
        name: "News73",
        component: () => import ('@/views/news/News73')
    },{
        path: "/news74",
        name: "News74",
        component: () => import ('@/views/news/News74')
    },{
        path: "/news75",
        name: "News75",
        component: () => import ('@/views/news/News75')
    },{
        path: "/news76",
        name: "News76",
        component: () => import ('@/views/news/News76')
    },{
        path: "/news77",
        name: "News77",
        component: () => import ('@/views/news/News77')
    },{
        path: "/news78",
        name: "News78",
        component: () => import ('@/views/news/News78')
    },{
        path: "/news79",
        name: "News79",
        component: () => import ('@/views/news/News79')
    },{
        path: "/news80",
        name: "News80",
        component: () => import ('@/views/news/News80')
    },{
        path: "/news81",
        name: "News81",
        component: () => import ('@/views/news/News81')
    },{
        path: "/news82",
        name: "News82",
        component: () => import ('@/views/news/News82')
    },{
        path: "/news85",
        name: "News85",
        component: () => import ('@/views/news/News85')
    },{
        path: "/news89",
        name: "News89",
        component: () => import ('@/views/news/News89')
    },{
        path: "/job/01",
        name: "Job01",
        component: () => import ('@/views/job/Job01')
    }, {
        path: "/job/02",
        name: "Job02",
        component: () => import ('@/views/job/Job02')
    }, {
        path: "/job/03",
        name: "Job03",
        component: () => import ('@/views/job/Job03')
    }, {
        path: "/job/04",
        name: "Job04",
        component: () => import ('@/views/job/Job04')
    }, {
        path: "/job/05",
        name: "Job05",
        component: () => import ('@/views/job/Job05')
    }, {
        path: "/job/06",
        name: "Job06",
        component: () => import ('@/views/job/Job06')
    }, {
        path: "/job/07",
        name: "Job07",
        component: () => import ('@/views/job/Job07')
    }, {
        path: "/job/08",
        name: "Job08",
        component: () => import ('@/views/job/Job08')
    }, {
        path: "/job/09",
        name: "Job09",
        component: () => import ('@/views/job/Job09')
    }, {
        path: "/404",
        name: "404",
        component: () => import ('@/views/PageNotFound')
    }, {
        path: "/business",
        name: "Business",
        component: () => import ('@/views/about/Business')
    }, {
        path: "/join",
        name: "Join",
        component: () => import ('@/views/about/Join')
    }, {
        path: "/team",
        name: "Team",
        component: () => import ('@/views/about/Team')
    }, {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    },
];

Vue.use(Router);
const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior: (from, to, savedPosition) => {
        if (to.hash) {
            return {selector: to.hash}
        }
        if (savedPosition) {
            return savedPosition;
        }
        window.scrollTo(0, 0);
    }
});

export default router;
